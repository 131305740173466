<template>
<v-container elevation="0">
  <v-row class="py-4" style="background-color:#F5F8FA;">
    <span class="text-h4"> 目标统计 </span>
  </v-row>

  <v-row class="px-3">
    <div class="dept_filter d-flex align-center">
      <date-range-picker ref="picker" opens="right" :locale-data="{ 
          firstDay: 1,
          format: 'yyyy-mm-dd',
          applyLabel: '确定',
          cancelLabel: '取消',
          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
          monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
          }" 
          minDate="2018-01-01" maxDate="2050-12-31" :dateRange="dateRange" :ranges="false" @update="updateDate" :append-to-body="true">
        <template v-slot:input="picker">
          <div class="text-subtitle-1">时间<v-icon style="font-size: 18px;">mdi-menu-down</v-icon>
          </div>
        </template>
      </date-range-picker>
    </div>
    <div class="dept_filter d-flex align-center">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            负责人所在部门 <v-icon style="font-size: 18px;">mdi-menu-down</v-icon>
          </div>
        </template>
        <v-card>
          <DepartmentGroupPicker @pick="pickDepartment" />
        </v-card>
      </v-menu>
    </div>

    <div class="dept_filter d-flex align-center">
      <v-checkbox v-model="showExcute" :label="`显示执行`"></v-checkbox>
    </div>

  </v-row>
  <v-row style="background-color:#F5F8FA;" class="pa-2">
    <span v-if="filterDate.startDate" class="pr-8">
      时间: {{filterDate.startDate+"~"+filterDate.endDate}}
      <v-icon size="15" color="rgb(169, 184, 191)" style="cursor:pointer;" class="file_remove_close ml-2" @click="removeDate">mdi-close-circle</v-icon>
    </span>
    <span v-if="!!filterDept && !!filterDept.id">
      负责人所在部门: {{filterDept.departmentOaName}}
      <v-icon size="15" color="rgb(169, 184, 191)" style="cursor:pointer;" class="file_remove_close ml-2" @click="removeDept">mdi-close-circle</v-icon>
    </span>
  </v-row>

  <v-row class="pa-5">
    <zk-table :data="this.thisTable" :columns="itemHeader" children-prop="task_tasks" index-text="#" :stripe="false" :border="false" :show-header="true" :show-summary="false" :show-row-hover="true" :show-index="true" :tree-type="true" :is-fold="false" :expand-type="false" :selection-type="false">
      <template slot="colName" scope="scope">
        <span>
          <v-icon @click="showLink(scope.row)" :style="{color:scope.row._level>=3?'blue':'black'}" small>mdi-link</v-icon>
        </span>
      </template>
      <template slot="colType" scope="scope">
        <span v-if="scope.row.ob_type!=null">
          {{objectType[scope.row.ob_type]}}
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template slot="colCompletion" scope="scope">
        <span v-if="scope.row.ob_progress!=null">
          {{scope.row.ob_progress+'%'}}<br />
          {{objectStatus[scope.row.ob_status]}}
        </span>
        <span v-if="scope.row.kr_completion!=null">
          {{scope.row.kr_completion+'%'}}
        </span>
        <span v-if="scope.row.task_progress!=null">
          {{scope.row.task_progress+'%'}}<br />
          {{taskStatus[scope.row.task_status-1]}}<br />
          {{getExpiredDays(scope.row.task_end_date)}}
        </span>
      </template>
      <template slot="colProgress" scope="scope">
        <span v-if="scope.row.ob_recent_pr!=null">
          {{getFormattedDate(scope.row.ob_recent_pr.created_at)}} {{scope.row.ob_recent_pr.employeeName}}<br />
          {{scope.row.ob_recent_pr.pr_description}}
        </span>
      </template>
      <template slot="colTaskRate" scope="scope">
        {{getCompletionStatus(scope.row.task_tasks)}}
      </template>
      <template slot="colScore" scope="scope">
        <span v-if="scope.row.ob_score!=null">
          {{scope.row.ob_score}}
        </span>
      </template>
      <template slot="colScoreDesc" scope="scope">
        <span v-if="scope.row.ob_score_description!=null">
          {{scope.row.ob_score_description}}
        </span>
      </template>
      <template slot="colOwnerName" scope="scope">
        <span v-if="scope.row.ob_owners!=null">
          {{scope.row.ob_owners['employeeName']}}
        </span>
        <span v-if="scope.row.kr_owners!=null">
          {{scope.row.kr_owners['employeeName']}}
        </span>
        <span v-if="scope.row.task_owner_name!=null">
          {{scope.row.task_owner_name}}
        </span>
      </template>
      <template slot="colDept" scope="scope">
        <span v-if="scope.row.ob_owners!=null">
          {{scope.row.ob_owners['departmentOaName']}}
        </span>
        <span v-if="scope.row.kr_owners!=null">
          {{scope.row.kr_owners['departmentOaName']}}
        </span>
        <span v-if="scope.row.task_dept_name!=null">
          {{scope.row.task_dept_name}}
        </span>
      </template>
      <template slot="colStartDate" scope="scope">
        <span v-if="scope.row.ob_start_date!=null">
          {{scope.row.ob_start_date}}
        </span>
        <span v-if="scope.row.kr_start_date!=null">
          {{scope.row.kr_start_date}}
        </span>
        <span v-if="scope.row.task_start_date!=null">
          {{scope.row.task_start_date}}
        </span>
      </template>
      <template slot="colEndDate" scope="scope">
        <span v-if="scope.row.ob_end_date!=null">
          {{scope.row.ob_end_date}}
        </span>
        <span v-if="scope.row.kr_end_date!=null">
          {{scope.row.kr_end_date}}
        </span>
        <span v-if="scope.row.task_end_date!=null">
          {{scope.row.task_end_date}}
        </span>
      </template>
    </zk-table>
  </v-row>
  <TaskEdit ref="taskEditor" />
  <RightObjectEditor ref="objectEditor" />
  <RightEditor ref="krEditor" />
</v-container>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  constant
} from '@/constants/constant.js';
import moment from 'moment';

import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: "GoalStatistic",
  components: {
    DateRangePicker,
    DatePicker,
    DepartmentGroupPicker: () => import('@/components/common/DepartmentGroupPicker.vue'),
    TaskEdit: () => import('@/components/common/taskEdit/TaskEdit.vue'),
    RightEditor: () => import('@/components/okrgoal/rightInfo/RightEditor.vue'),
    RightObjectEditor: () => import('@/components/okrmap/RightObjectEditor.vue'),
    // CreateGoal: () => import("@/components/okrgoal/CreateGoal.vue"),
  },
  data() {
    return {
      showExcute: false,
      objectType: [],
      objectStatus: ['进行中', '已结束'],
      taskStatus: [],
      dateRange: {
        startDate: null,
        endDate: null,
      },
      filterDate: {
        startDate: null,
        endDate: null,
      },
      filterDept: null,
      isAddGoal: false,
      itemHeader: [{
          label: '目标/KR',
          prop: 'task_name',
          width: '350px'
        },
        {
          label: '',
          type: 'template',
          template: 'colName',
          width: '30px'
        },
        {
          label: '类型',
          type: 'template',
          template: 'colType',
          width: '70px'
        },
        {
          label: '完成度',
          type: 'template',
          template: 'colCompletion',
          width: '70px'
        },
        {
          label: '进展',
          type: 'template',
          template: 'colProgress',
          width: '150px'
        },
        {
          label: '任务完成率',
          type: 'template',
          template: 'colTaskRate',
          width: '100px'
        },
        {
          label: '评分',
          type: 'template',
          template: 'colScore',
          width: '60px'
        },
        {
          label: '评分说明',
          type: 'template',
          template: 'colScoreDesc'
        },
        {
          label: '负责人',
          type: 'template',
          template: 'colOwnerName'
        },
        {
          label: '部门/职位',
          type: 'template',
          template: 'colDept',
          width: '160px'
        },
        {
          label: '开始时间',
          type: 'template',
          template: 'colStartDate'
        },
        {
          label: '结束时间',
          type: 'template',
          template: 'colEndDate'
        },
      ],

    };
  },
  mounted() {
    this.getOkrs({})
    this.objectType = constant.objectType;
    this.taskStatus = constant.taskStatus.map(item => {
      return item.text;
    });
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters('okr', ['okrObj']),
    thisTable() {
      return this.okrObj.map((obj) => {
        obj['task_tasks'] = obj['ob_results'];
        obj['task_name'] = 'O : ' + obj['ob_name'];
        // delete obj['ob_results'];
        obj['task_tasks'] = obj['task_tasks'].map((kr) => {
          kr['task_name'] = 'KR' + kr['kr_order'] + ' : ' + kr['kr_name'];
          if (this.showExcute) {
            kr['task_tasks'] = kr['kr_tasks'];
          } else {
            kr['task_tasks'] = new Array();
          }
          return kr;
        });
        return obj;
      }).filter((res) => {
        let chk = true;
        let startDate = new Date(res.ob_start_date);
        let endDate = new Date(res.ob_end_date);
        if (!!this.filterDate.startDate) {
          let filterStartDate = new Date(this.filterDate.startDate);
          let filterEndDate = new Date(this.filterDate.endDate);
          chk = !((endDate < filterStartDate) || (filterEndDate < startDate))
        }
        if (!!this.filterDept && !!this.filterDept.id) {
          chk = chk && (res.ob_owners.user_dp_serial + '.').includes(this.filterDept.dp_serial_id + '.' + this.filterDept.id + '.')
        }
        return chk;
      });
    },
  },
  methods: {
    ...mapActions('okr', ['getOkrs']),
    showLink(param) {
      if (!!param.ob_id) {
        this.$refs.objectEditor.open(param)
      }
      if (!!param.kr_id) {
        this.$refs.krEditor.open({
          krObj: param,
          obId: param.kr_parent_object,
          obStatus: 0,
        });
      }
      if (!!param.task_id) {
        this.$refs.taskEditor.openTaskEditor(param);
      }
    },
    updateData() {
      this.getOkrs({})
    },
    getFormattedDate(newDate) {
      return moment(newDate).format("YYYY/MM/DD")
    },
    getExpiredDays(stopDate) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let s1 = new Date(stopDate);
      let s2 = new Date();
      '过期天'
      let diff = Math.floor((s1 - s2) / _MS_PER_DAY);
      return diff > 0 ? '过期' + diff + '天' : null;
    },
    getCompletionStatus(tasks) {
      let allCnt = tasks.length;
      let completedCnt = 0;
      tasks.forEach(ele => {
        if (ele.kr_completion && ele.kr_completion == 100) {
          completedCnt++;
        }
        if (ele.task_status && (ele.task_status == 1 || ele.task_progress == 100)) {
          completedCnt++;
        }
      });
      return allCnt == 0 ? '' : Math.floor(completedCnt / allCnt * 100.0) + '%(' + completedCnt + '/' + allCnt + ')'
    },
    pickDepartment(param) {
      this.filterDept = param;
    },
    updateDate(param) {
      this.dateRange = param;
      this.filterDate.startDate = moment(new Date(this.dateRange.startDate)).format("YYYY-MM-DD");
      this.filterDate.endDate = moment(new Date(this.dateRange.endDate)).format("YYYY-MM-DD");
    },
    removeDate() {
      this.filterDate.startDate = null;
      this.filterDate.endDate = null;
    },
    removeDept() {
      this.filterDept = null;
    }
  },
};
</script>

<style scoped>
.dept_filter {
  display: inline-block;
  min-width: 60px;
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
}
</style>
